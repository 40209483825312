import Port1 from "../../assets/port1.png";
import Port2 from "../../assets/port2.png";
import Port3 from "../../assets/port3.png";
import Port4 from "../../assets/port4.png";
import Port5 from "../../assets/port5.png";
import Port6 from "../../assets/port6.png";
import Port7 from "../../assets/port7.png";

export const projectData = [
  {
    id: 1,
    image: Port1,
    title: "Profile Company",
    category: "fullstack",
    desc: "Web that contains information about the WIKA REKON company. Starting from the vision and mission, history, and others.",
  },
  {
    id: 2,
    image: Port2,
    title: "Archieve Website",
    category: "fullstack",
    desc: "WIKA's Web Archiving is a site for the process of collecting documents and ensuring that the collection is preserved in an archive.",
  },
  {
    id: 3,
    image: Port3,
    title: "Leave Application Website",
    category: "fullstack",
    desc: "Leave application website is a site owned by WIKA REKON to make it easier to apply for holidays for working employees.",
  },
  {
    id: 4,
    image: Port4,
    title: "Music API",
    category: "backend",
    desc: "Music data APIs are application programming interfaces that allow developers to access and integrate the functionalities of music applications or services, such as streaming and downloading audio files, into their own product.",
  },
  {
    id: 5,
    image: Port5,
    title: "School Website",
    category: "frontend",
    desc: "The school website is the online presence of a school which clearly represents all information about it so that it can make it easier for all parents, students and staff.",
  },
  {
    id: 6,
    image: Port6,
    title: "First Step",
    category: "fullstack",
    desc: "First Step is an e-commerce platform with a specialized focus on baby and maternity essentials. This project seamlessly integrates features for shipping and payment, utilizing RajaOngkir for shipping calculations and Midtrans for secure transactions. Explore the official website at https://first-step.my.id/ to discover a comprehensive selection of products designed to cater to the diverse needs of both mothers and infants.",
  },
  {
    id: 7,
    image: Port7,
    title: "Project Management App",
    category: "fullstack",
    desc: '"The Project Management App is a platform designed to facilitate effective and efficient project management. This app includes features for scheduling, task tracking, team collaboration, and project progress reporting. With its intuitive interface and deep integration with various management tools, it helps teams work more productively and keeps projects on track. Ideal for project managers and teams needing an all-in-one solution to manage their work.',
  },
];

export const projectsNav = [
  {
    name: "all",
  },
  {
    name: "frontend",
  },
  {
    name: "backend",
  },
  {
    name: "fullstack",
  },
];
